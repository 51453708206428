import cls from './Final.module.scss';
import moneyIcon from '../../shared/assets/images/money.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../shared/redux/store';
import { OnbPages, setOnboardingPage } from '../../shared/redux/onbPageSlice';
import { Link, useNavigate } from 'react-router-dom';
import { resetCount, setTryCount } from '../../shared/redux/game/slice';

import { getBestScore } from '../../shared/ui/Api/getFunc';
import { useEffect, useState } from 'react';
import { shareScore } from '../../shared/ui/Api/shareTG';
import { playButtonSound } from '../../shared/ui/SoundComponent/SoundComponent';

const Final = () => {
  const count = useSelector((state: RootState) => state.game.count);
  const [bestCount, setBestCount] = useState<number>(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function checkEnding(num: number) {
    let lastDigit = num % 10;
    let lastTwoDigits = num % 100;

    if (lastTwoDigits >= 10 && lastTwoDigits <= 20) {
      return 'раз';
    } else if (lastDigit === 1) {
      return 'раз';
    } else if (lastDigit >= 2 && lastDigit <= 4) {
      return 'раза';
    } else {
      return 'раз';
    }
  }

  useEffect(() => {
    const fetchBestScore = async () => {
      try {
        const score = await getBestScore();
        if (score !== null) {
          setBestCount(score);
        } else {
          setBestCount(0);
        }
      } catch (error) {
        console.error('Произошла ошибка при получении счета:', error);
      }
    };
    fetchBestScore();
  }, []); // Пустой массив зависимостей, чтобы выполнить этот эффект один раз при монтировании

  return (
    <div className={cls.container}>
      <div className={cls.contentContainer}>
        <p>
          Сегодня ты пинал х*й вместо работы {count} {checkEnding(count)}.
        </p>
        <div className={cls.counter}>
          <img alt="Money" src={moneyIcon} className={cls.moneyIcon} />
          <div>{count}</div>
        </div>
        {bestCount < count ? (
          <h1>Поздравляем, это твой личный рекорд!</h1>
        ) : (
          <h1>
            А твой рекорд: {bestCount} {checkEnding(bestCount)}
          </h1>
        )}

        <div className={cls.buttonContainer}>
          <button
            onClick={() => {
              playButtonSound();
              dispatch(resetCount());
              dispatch(setTryCount(0));
              dispatch(setOnboardingPage(OnbPages.MENU));
              navigate('/');
            }}>
            ПОПИНАТЬ ЕЩЕ!
          </button>

          {window.innerWidth < 1000 && <button onClick={() => shareScore()}>Дать пас другу</button>}
        </div>
        <p className={cls.onboard}>
          Чтобы не&nbsp;возвращаться к&nbsp;работе прямо сразу, посмотри х*еборд и&nbsp;узнай,
          на&nbsp;каком ты&nbsp;месте в&nbsp;топе главных пропинателей жизни!
        </p>
        <Link
          to={'/table'}
          onClick={() => {
            playButtonSound();
          }}>
          Посмотреть х*еборд
        </Link>
      </div>
    </div>
  );
};

export default Final;
