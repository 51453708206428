import { useEffect } from 'react';
import Popup from '../../shared/ui/Popup/Popup';
import SoundComponent from '../../shared/ui/SoundComponent/SoundComponent';
import cls from './PopupPage.module.scss';
import { setUserScore } from '../../shared/ui/Api/getFunc';
import { useSelector } from 'react-redux';
import { RootState } from '../../shared/redux/store';

const PopupPage = () => {
  const count = useSelector((state: RootState) => state.game.count);
  useEffect(() => {
    setUserScore(count);
  }, []);
  return (
    <div className={cls.container}>
      <div className={cls.buttonContainer}>
        <SoundComponent />
      </div>
      <Popup />
    </div>
  );
};

export default PopupPage;
