import cls from './Game.module.scss';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../shared/redux/store';
import { Ball } from '../../shared/redux/chooseSlice';
import ClassicBall from '../../shared/assets/images/penis-classic.svg';
import RibbedBall from '../../shared/assets/images/penis-ribbed.png';
import EyeBall from '../../shared/assets/images/penis-eye.svg';
import BlackBall from '../../shared/assets/images/penis-black.svg';
import Lottie from 'lottie-react';
import ClickAnimation from '../../shared/assets/images/click-anim.json';
import LegAnimation from '../../shared/assets/images/leg-anim.json';
import ButtonMenu from '../../shared/ui/ButtonMenu/ButtonMenu';
import Obstacles from '../../shared/ui/Obstacles/Obstacles';
import flappySound from '../../shared/assets/sound/flappy.aac';
import { useSpring, animated } from '@react-spring/web';
import {
  incrementCount,
  setIsVisiblePopup,
  setPause,
  setTryCount,
} from '../../shared/redux/game/slice';
import { useNavigate } from 'react-router-dom';
import failSound from '../../shared/assets/sound/fail.aac';
import moneySound from '../../shared/assets/sound/money.aac';
import { resetObstacles, setElementCounted } from '../../shared/redux/obstacles/slice';
import { Howl } from 'howler';
import { stateObsType } from '../../shared/redux/obstacles/types';
import { doPolygonsIntersect, rotateBird } from '../../shared/redux/game/checkProcess';

const soundFail = new Howl({
  src: [failSound],
});

const soundMoney = new Howl({
  src: [moneySound],
});

let dropTimer: ReturnType<typeof setTimeout>; // таймер для опускания

const getBallImage = (chooseBall: string) => {
  if (chooseBall === Ball.BLACK) {
    return BlackBall;
  } else if (chooseBall === Ball.RIBBED) {
    return RibbedBall;
  } else if (chooseBall === Ball.EYE) {
    return EyeBall;
  } else {
    return ClassicBall;
  }
};

// let animationContainerFrameId: number;
let intervalBall: ReturnType<typeof setInterval>;
let intervalContainer: ReturnType<typeof setInterval>;

const Game = () => {
  console.log('Game component rerendered');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [firstClick, setFirstClick] = useState(true);
  const rotateValueRef = useRef(0);
  const [isFail, setIsFail] = useState(false);
  const [canClick, setCanClick] = useState(false);
  const [isTotalFail, setTotalFail] = useState(false);

  const { tryCount, pause, isSound } = useSelector((state: RootState) => state.game);
  const { obstacles } = useSelector((state: RootState) => state.obstacles);
  const chooseBall = useSelector((state: RootState) => state.choose.chooseBall);
  const ballImage = useMemo(() => getBallImage(chooseBall), [chooseBall]);
  // const [svgImage, setSvgImage] = useState<React.ReactElement[]>([]);

  const halfwindowHeight = window.innerHeight / 2;
  const containerHeight = window.innerHeight > 640 ? 666 : 560;
  const bottomBlueLine =
    window.innerHeight > 640
      ? window.innerHeight - halfwindowHeight - 350
      : window.innerHeight - halfwindowHeight - 280;
  const topBlueLine = window.innerHeight > 640 ? halfwindowHeight - 350 : halfwindowHeight - 280;

  //Параметры контейнера
  const containerLeftRef = useRef(0);
  const [springPropsContainer, setSpringPropsContainer] = useSpring(() => ({
    transform: `translate3D(0px, 0px, 0px)`,
    config: { tension: 350, friction: 30 },
  }));

  //Параметры Пениса
  const bottomBallRef = useRef(282);
  const leftBallRef = useRef(84);
  const obstaclesRef = useRef<stateObsType[]>([]);
  const containerRef: React.RefObject<HTMLDivElement> = useRef(null);
  const changeLeft = useRef(20);

  const [springPropsBall, setSpringPropsBall] = useSpring(() => ({
    bottom: `282px`,
    left: `84px`,
    transform: 'rotate(0deg)',
    config: { tension: 350, friction: 30 },
  }));

  //Используем каждые 50 ms
  const checkProcess = useCallback(() => {
    obstaclesRef.current.map((el, idx) => {
      if (el.counted) return; // Пропускаем препятствие, если оно уже было учтено

      // значение left у препятствия от левого края экрана
      const nullMoment = containerLeftRef.current + el.absoluteLeft;
      if (nullMoment < 400) {
        const birdX0 = leftBallRef.current;
        const birdY0 = window.innerHeight - bottomBallRef.current - 85;
        const birdX1 = leftBallRef.current + 40;
        const birdY1 = window.innerHeight - bottomBallRef.current;

        // Положение правого края препятствия относительно начальной позиции шара
        const passMoment = nullMoment + el.item.width;
        if (passMoment < 154) {
          soundMoney.play();
          dispatch(incrementCount());
          dispatch(setElementCounted(idx));
          return;
        }

        el.boundaries.map((bound) => {
          const obsX0 = nullMoment + bound[0].x;
          const obsX1 = el.item.isTop ? nullMoment + 20 + bound[1].x : nullMoment - 10 + bound[1].x;
          const obsY0 = el.item.isTop
            ? topBlueLine + bound[0].y
            : topBlueLine + containerHeight - el.item.height + bound[0].y + 30;
          const obsY1 = el.item.isTop
            ? topBlueLine + bound[1].y - 10
            : topBlueLine + containerHeight - el.item.height + bound[1].y;

          if (
            (el.item.isTop && birdY0 > obsY1 && birdY1 > obsY1) ||
            (!el.item.isTop && birdY0 < obsY0 && birdY1 < obsY0) ||
            (birdX0 < obsX0 && birdX1 < obsX0) ||
            (birdX0 > obsX1 && birdX1 > obsX1)
          ) {
            return;
          } else {
            console.log('CHECK');

            const obstacleVertices = [
              { x: obsX0, y: obsY0 },
              { x: obsX1, y: obsY0 },
              { x: obsX1, y: obsY1 },
              { x: obsX0, y: obsY1 },
            ];

            const bird = [
              { x: birdX0, y: birdY0 },
              { x: birdX1, y: birdY0 },
              { x: birdX1, y: birdY1 },
              { x: birdX0, y: birdY1 },
            ];

            const birdVertices = rotateBird(bird, rotateValueRef.current);
            const isCollide = doPolygonsIntersect(birdVertices, obstacleVertices);

            if (isCollide) {
              setTimeout(() => {
                setIsFail(true);
              }, 60);
            }
          }
        });
      }
    });
  }, []);

  const startIntervalContainer = useCallback(() => {
    intervalContainer = setInterval(() => {
      containerLeftRef.current = containerLeftRef.current - changeLeft.current;
      setSpringPropsContainer({
        transform: `translate3D(${containerLeftRef.current}px, 0px, 0px)`,
      });
      checkProcess();
      changeLeft.current += 0.05;
    }, 100);
  }, []);

  //При нажатии на play, при первом клике, и если не нажимали какое-то время
  const startIntervalBall = useCallback((isClick: boolean) => {
    if (!pause && !isClick) {
      intervalBall = setInterval(() => {
        bottomBallRef.current -= 12;

        setSpringPropsBall((prev: any) => ({
          ...prev,
          bottom: `${bottomBallRef.current}px`,
        }));

        const soLow = bottomBallRef.current < bottomBlueLine;
        if (soLow) {
          setIsFail(true);
        }
      }, 50);
    } else {
      clearInterval(intervalBall);
    }
  }, []);

  const soundFlappy = new Howl({
    src: [flappySound],
  });

  //Используем при клике на контейнер
  const onClickContainer = () => {
    if (!pause) {
      soundFlappy.play();
      clearTimeout(dropTimer);

      if (!firstClick) {
        if (canClick) {
          bottomBallRef.current += 80;
        }
        setSpringPropsBall({
          bottom: `${bottomBallRef.current}px`,
          left: `${leftBallRef.current}px`,
          transform: 'rotate(45deg)',
        });
        rotateValueRef.current = 45;
        startIntervalBall(true);
      } else {
        setTimeout(() => {
          setCanClick(true);
        }, 1000);
        startIntervalContainer();
        if (window.innerHeight > 640) {
          bottomBallRef.current += 280;
        } else if (window.innerHeight > 580) {
          bottomBallRef.current += 180;
        } else {
          bottomBallRef.current += 130;
        }
        const newLeft = window.innerWidth < 500 ? 20 : 100;
        leftBallRef.current += newLeft;
        setTimeout(() => {
          setSpringPropsBall({
            bottom: `${bottomBallRef.current}px`,
            left: `${leftBallRef.current}px`,
            transform: 'rotate(45deg)',
          });
          rotateValueRef.current = 45;
        }, 170);
        setFirstClick(false);
      }

      //член наверху
      const soHigh = bottomBallRef.current + 55 > halfwindowHeight + containerHeight / 2;
      if (soHigh) {
        setTimeout(() => {
          setIsFail(true);
        }, 20);
      }

      // Задержка перед установкой угла обратно на 135deg
      dropTimer = setTimeout(() => {
        startIntervalBall(false);
        setSpringPropsBall((prev: any) => ({
          ...prev,
          transform: `rotate(135deg)`,
        }));
        rotateValueRef.current = 135;
      }, 300);
    } else {
      clearInterval(intervalContainer);
      clearInterval(intervalBall);
    }
  };

  const touchStartedRef = useRef(false);

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    onClickContainer();
    touchStartedRef.current = true;
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (touchStartedRef.current) {
      // Игнорируем событие onClick
      touchStartedRef.current = false; // сбрасываем флаг
      return;
    }
    onClickContainer();
  };

  useEffect(() => {
    dispatch(setTryCount(tryCount + 1));
    containerLeftRef.current = 0;
    obstaclesRef.current = [];
    return () => {
      // Очищаем интервал при unmount компонента или изменении pause
      clearInterval(intervalBall);
      clearInterval(intervalContainer);
    };
  }, []);

  useEffect(() => {
    if (isFail && !isTotalFail) {
      soundFail.play();
      bottomBallRef.current = window.innerHeight / 2 - 333;
      setSpringPropsBall({
        bottom: `${bottomBallRef.current}px`,
      });

      dispatch(setPause(true));
      setTimeout(() => {
        dispatch(setIsVisiblePopup(true));
        navigate('/popup');
        dispatch(resetObstacles());
      }, 300);
      setTotalFail(true);
      setIsFail(false);
    }
  }, [isFail]);

  useEffect(() => {
    if (firstClick) {
      dispatch(setTryCount(tryCount + 1));
    }
    if (pause) {
      clearInterval(intervalBall);
      clearInterval(intervalContainer);
    } else if (!firstClick) {
      startIntervalBall(false);
      startIntervalContainer();
      if (containerRef.current) {
        containerRef.current.focus();
      }
    }
  }, [pause]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.focus();
    }
  }, [isSound]);

  useEffect(() => {
    obstaclesRef.current = obstacles;
  }, [obstacles]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.focus();
    }
  }, []);

  return (
    <div className={cls.mainContainer}>
      <ButtonMenu firstClick={firstClick} />

      <animated.img
        className={`${cls.BallImage}`}
        alt="Ball"
        src={ballImage}
        style={{ ...springPropsBall }}
      />
      <animated.div
        ref={containerRef}
        className={cls.container}
        style={{ ...springPropsContainer }}
        onTouchStart={(ev) => handleTouchStart(ev)}
        onClick={(ev) => handleClick(ev)}
        onKeyDown={(ev) => {
          if (ev.code === 'Space') {
            onClickContainer();
          }
        }}
        tabIndex={0}>
        {/* {svgImage} */}

        {!firstClick && <Lottie animationData={LegAnimation} className={cls.legImg} loop={false} />}
        {firstClick && (
          <>
            <Lottie className={cls.cursorImage} animationData={ClickAnimation} loop={true} />
            <p>
              тапай по экрану,
              <br />
              чтобы проходить
              <br />
              препятствия
            </p>
          </>
        )}
        <canvas id="collisionCanvas" width="400" height="400"></canvas>
        <Obstacles />
      </animated.div>
    </div>
  );
};

export default Game;
