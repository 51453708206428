import cls from './PenisMenu.module.scss';
import BallClassic from '../../shared/assets/images/penis-classic.svg';
import BallRibbed from '../../shared/assets/images/penis-ribbed.png';
import BallEye from '../../shared/assets/images/penis-eye.svg';
import BallBlack from '../../shared/assets/images/penis-black.svg';
import { useDispatch } from 'react-redux';
import { Ball, setBall } from '../../shared/redux/chooseSlice';
import { Link } from 'react-router-dom';
import { resetCount, setPause } from '../../shared/redux/game/slice';
import { resetObstacles } from '../../shared/redux/obstacles/slice';
import { playButtonSound } from '../../shared/ui/SoundComponent/SoundComponent';

const BallMenu = () => {
  const dispatch = useDispatch();

  const onClickBall = (item: Ball) => {
    dispatch(setBall(item));
    playButtonSound();
    dispatch(resetCount());
    dispatch(resetObstacles());
    dispatch(setPause(false));
  };
  return (
    <div className={cls.container}>
      <h2>
        выбери
        <br />
        на свой вкус
      </h2>
      <div className={cls.items}>
        <Link to="/game">
          <div className={cls.BallItem} onClick={() => onClickBall(Ball.ClASSIC)}>
            <img src={BallClassic} alt="Classic Ball" aria-disabled />
          </div>
        </Link>
        <Link to="/game">
          <div className={cls.BallItem} onClick={() => onClickBall(Ball.RIBBED)}>
            <img src={BallRibbed} alt="Ribbed Ball" aria-disabled />
          </div>
        </Link>
        <Link to="/game">
          <div className={cls.BallItem} onClick={() => onClickBall(Ball.EYE)}>
            <img src={BallEye} alt="Ball with eye" aria-disabled />
          </div>
        </Link>
        <Link to="/game">
          <div className={cls.BallItem} onClick={() => onClickBall(Ball.BLACK)}>
            <img src={BallBlack} alt="Black Ball" aria-disabled />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default BallMenu;
