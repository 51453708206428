// Функция для выполнения смещения вершин
function translateVertices(vertices, dx, dy) {
  return vertices.map((vertex) => ({
    x: vertex.x + dx,
    y: vertex.y + dy,
  }));
}

// Функция для поворота вершин на заданный угол
function rotateVertices(vertices, angle) {
  const radians = (angle * Math.PI) / 180;
  const cosTheta = Math.cos(radians);
  const sinTheta = Math.sin(radians);

  return vertices.map((vertex) => ({
    x: vertex.x * cosTheta - vertex.y * sinTheta,
    y: vertex.x * sinTheta + vertex.y * cosTheta,
  }));
}

// Функция для поворота "птички" относительно её центра
export function rotateBird(bird, angle) {
  // Находим центр "птички"
  const centerX = (bird[0].x + bird[2].x) / 2;
  const centerY = (bird[0].y + bird[2].y) / 2;

  // Выполняем смещение вершин так, чтобы центр совпал с начальной точкой (0, 0)
  const translatedBird = translateVertices(bird, -centerX, -centerY);

  // Поворачиваем "птичку"
  const rotatedBird = rotateVertices(translatedBird, angle);

  // Возвращаем вершины в исходное положение
  return translateVertices(rotatedBird, centerX, centerY);
}

export function doPolygonsIntersect(birdVertices, obstacleVertices) {
  function isInside(vertices, point) {
    let isInside = false;
    for (let i = 0, j = vertices.length - 1; i < vertices.length; j = i++) {
      const xi = vertices[i].x;
      const yi = vertices[i].y;
      const xj = vertices[j].x;
      const yj = vertices[j].y;
      const intersect =
        yi > point.y !== yj > point.y && point.x < ((xj - xi) * (point.y - yi)) / (yj - yi) + xi;
      if (intersect) isInside = !isInside;
    }
    return isInside;
  }

  for (const vertex of birdVertices) {
    if (isInside(obstacleVertices, vertex)) {
      return true;
    }
  }

  for (const vertex of obstacleVertices) {
    if (isInside(birdVertices, vertex)) {
      return true;
    }
  }

  return false;
}
