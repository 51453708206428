import { useDispatch, useSelector } from 'react-redux';
import AgeContainer from '../../widgets/AgeContainer/AgeContainer';
import FlappyOnboarding from '../../widgets/FlappyOnboarding/FlappyOnboarding';
import PenisMenu from '../../widgets/PenisMenu/PenisMenu';
import cls from './Onboarding.module.scss';
import { RootState } from '../../shared/redux/store';
import { OnbPages } from '../../shared/redux/onbPageSlice';
import SoundComponent from '../../shared/ui/SoundComponent/SoundComponent';
import { useEffect } from 'react';
import { setSound, setTryCount } from '../../shared/redux/game/slice';

const Onboarding = () => {
  const currPage = useSelector((state: RootState) => state.onbPage.currentPage);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTryCount(0));
  }, []);
  const setSoundTrue = () => {
    if (currPage === OnbPages.AGE) {
      dispatch(setSound(true));
    }
  };
  return (
    <div className={cls.Onboarding} onClick={setSoundTrue}>
      {currPage === OnbPages.AGE && <AgeContainer />}
      {currPage === OnbPages.FlAPPY && <FlappyOnboarding />}
      {currPage === OnbPages.MENU && <PenisMenu />}
      <div className={cls.sound}>
        <SoundComponent />
      </div>
    </div>
  );
};

export default Onboarding;
