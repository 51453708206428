import { ThunkArgument } from './types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ObstaclesArr, boundArr } from './assets';

export const fetchBodyAndAddObstacle = createAsyncThunk(
  'obstacles/fetchBodyAndAdd',
  async (params: ThunkArgument) => {
    const { idx, count } = params;

    const obs = ObstaclesArr[idx];
    const absoluteLeft = 800 + 700 * count;
    const absoluteBottom = obs.isTop ? 666 - obs.height : 0;
    const verticalStyle = window.innerHeight > 640 ? 'calc(50% - 333px)' : 'calc(50% - 280px)';

    const styleObs = obs.isTop
      ? { left: `calc(800px + 700px * ${count})`, top: verticalStyle, height: obs.height }
      : { left: `calc(800px + 700px * ${count})`, bottom: verticalStyle, height: obs.height };

    const boundaries = boundArr.filter((el) => {
      if (el.id === obs.id) {
        return el.boundaries;
      }
    })[0].boundaries;

    return {
      item: obs,
      absoluteLeft,
      absoluteBottom,
      boundaries,
      style: styleObs,
      counted: false,
    };
  },
);
