type CustomExternal = {
  notify: (message: string) => void;
} & typeof window.external;

type InitParamsType = {
  [key: string]: string | null;
} & {
  _path?: string;
};

type EventData = any;
type EventType = string;

let eventHandlers: { [eventType: string]: Function[] } = {};

const locationHash: string = window.location.hash;
const initParams: InitParamsType = urlParseHashParams(locationHash);
let isIframe: boolean = false;

try {
  isIframe = window.parent !== null && window !== window.parent;
} catch (e) {
  // Handle error if needed
}

function urlSafeDecode(urlencoded: string): string {
  try {
    return decodeURIComponent(urlencoded);
  } catch (e) {
    return urlencoded;
  }
}

function urlParseHashParams(locationHash: string): InitParamsType {
  locationHash = locationHash.replace(/^#/, '');
  let params: InitParamsType = {};
  if (!locationHash.length) {
    return params;
  }
  if (locationHash.indexOf('=') < 0 && locationHash.indexOf('?') < 0) {
    params._path = urlSafeDecode(locationHash);
    return params;
  }
  var qIndex = locationHash.indexOf('?');
  if (qIndex >= 0) {
    var pathParam = locationHash.substr(0, qIndex);
    params._path = urlSafeDecode(pathParam);
    locationHash = locationHash.substr(qIndex + 1);
  }
  var locationHashParams = locationHash.split('&');
  var i, param, paramName, paramValue;
  for (i = 0; i < locationHashParams.length; i++) {
    param = locationHashParams[i].split('=');
    paramName = urlSafeDecode(param[0]);
    paramValue = param[1] == null ? null : urlSafeDecode(param[1]);
    params[paramName] = paramValue;
  }
  return params;
}

function urlAppendHashParams(url: string, addHash: string): string {
  // url looks like 'https://game.com/path?query=1#hash'
  // addHash looks like 'tgShareScoreUrl=' + encodeURIComponent('tgb://share_game_score?hash=very_long_hash123')

  var ind = url.indexOf('#');
  if (ind < 0) {
    // https://game.com/path -> https://game.com/path#tgShareScoreUrl=etc
    return url + '#' + addHash;
  }
  var curHash = url.substr(ind + 1);
  if (curHash.indexOf('=') >= 0 || curHash.indexOf('?') >= 0) {
    // https://game.com/#hash=1 -> https://game.com/#hash=1&tgShareScoreUrl=etc
    // https://game.com/#path?query -> https://game.com/#path?query&tgShareScoreUrl=etc
    return url + '&' + addHash;
  }
  // https://game.com/#hash -> https://game.com/#hash?tgShareScoreUrl=etc
  if (curHash.length > 0) {
    return url + '?' + addHash;
  }
  // https://game.com/# -> https://game.com/#tgShareScoreUrl=etc
  return url + addHash;
}

function postEvent(eventType: EventType, callback: Function, eventData?: EventData) {
  if (!callback) {
    callback = function () {};
  }

  if (eventData === undefined) {
    eventData = '';
  }

  if ((window as any).TelegramWebviewProxy !== undefined) {
    (window as any).TelegramWebviewProxy.postEvent(eventType, JSON.stringify(eventData));
    callback();
  } else if (window.external && (window.external as CustomExternal).notify) {
    (window.external as CustomExternal).notify(
      JSON.stringify({ eventType: eventType, eventData: eventData }),
    );
    callback();
  } else if (isIframe) {
    try {
      let trustedTarget = 'https://web.telegram.org';
      // For now we don't restrict target, for testing purposes
      trustedTarget = '*';
      window.parent.postMessage(
        JSON.stringify({ eventType: eventType, eventData: eventData }),
        trustedTarget,
      );
    } catch (e) {
      callback(e);
    }
  } else {
    callback({ notAvailable: true });
  }
}

function receiveEvent(eventType: EventType, eventData: EventData): void {
  var curEventHandlers = eventHandlers[eventType];
  if (curEventHandlers === undefined || !curEventHandlers.length) {
    return;
  }
  for (var i = 0; i < curEventHandlers.length; i++) {
    try {
      curEventHandlers[i](eventType, eventData);
    } catch (e) {}
  }
}

function onEvent(eventType: EventType, callback: Function): void {
  if (eventHandlers[eventType] === undefined) {
    eventHandlers[eventType] = [];
  }
  var index = eventHandlers[eventType].indexOf(callback);
  if (index === -1) {
    eventHandlers[eventType].push(callback);
  }
}

function offEvent(eventType: EventType, callback: Function): void {
  if (eventHandlers[eventType] === undefined) {
    return;
  }
  var index = eventHandlers[eventType].indexOf(callback);
  if (index === -1) {
    return;
  }
  eventHandlers[eventType].splice(index, 1);
}

function openProtoUrl(url: string): boolean {
  if (!url.match(/^(web\+)?tgb?:\/\/./)) {
    return false;
  }
  var useIframe = navigator.userAgent.match(/iOS|iPhone OS|iPhone|iPod|iPad/i) ? true : false;
  if (useIframe) {
    var iframeContEl = document.getElementById('tgme_frame_cont') || document.body;
    var iframeEl = document.createElement('iframe');
    iframeContEl.appendChild(iframeEl);
    var pageHidden = false;
    var enableHidden = function () {
      pageHidden = true;
    };
    window.addEventListener('pagehide', enableHidden, false);
    window.addEventListener('blur', enableHidden, false);
    if (iframeEl !== null) {
      iframeEl.src = url;
    }
    setTimeout(function () {
      if (!pageHidden) {
        window.location.href = url;
      }
      window.removeEventListener('pagehide', enableHidden, false);
      window.removeEventListener('blur', enableHidden, false);
    }, 2000);
  } else {
    window.location.href = url;
  }
  return true;
}

// Exposed methods
export function shareScore(): void {
  postEvent('share_score', function (error: any) {
    if (error) {
      const shareScoreUrl = initParams.tgShareScoreUrl;
      if (shareScoreUrl) {
        openProtoUrl(shareScoreUrl);
      }
    }
  });
}
