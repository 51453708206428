import { useDispatch, useSelector } from 'react-redux';
import cls from './Popup.module.scss';
import { setIsVisiblePopup, setPause } from '../../redux/game/slice';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../redux/store';
import { useCallback } from 'react';
import { playButtonSound } from '../SoundComponent/SoundComponent';

const Popup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tryCount } = useSelector((state: RootState) => state.game);
  const speechArr = [
    'М-м-м, у тебя такой большой игровой потенциал!',
    'Скажи, что я крепкий, как хозяйственник!',
    'Давай, запинай меня глубже!',
    'Я чувствую, как ты горяч, даже через экран!',
    'Это очко персонально от меня!',
    'Только не в меня… а, пофиг, давай в меня!',
    'Ты лучший игрок, который у меня был!',
    'А давай ты сегодня вообще не будешь работать?',
    'Не кончай играть!',
  ];
  const randomNumber = Math.floor(Math.random() * speechArr.length);
  const randomSpeech = useCallback(() => speechArr[randomNumber], [tryCount]);
  return (
    <div className={cls.PopopContainer}>
      <p>{randomSpeech()}</p>
      <div className={cls.buttonContainer}>
        {tryCount < 2 && (
          <button
            className={cls.firstButton}
            onClick={() => {
              playButtonSound();
              navigate('/game');
              dispatch(setPause(false));
              dispatch(setIsVisiblePopup(false));
            }}>
            Продолжить
          </button>
        )}
        <button
          onClick={() => {
            playButtonSound();
            navigate('/final');
            dispatch(setPause(false));
            dispatch(setIsVisiblePopup(false));
          }}>
          Выбрать новый х*й
        </button>
      </div>
    </div>
  );
};

export default Popup;
