import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { stateObsType, stateType } from './types';
import { fetchBodyAndAddObstacle } from './thunk';

const initialState: stateType = {
  obstacles: [],
  obsCount: 0,
};

export const obstacles = createSlice({
  name: 'obstacles',
  initialState,
  reducers: {
    addObstacle(state, action: PayloadAction<stateObsType>) {
      state.obstacles = [...state.obstacles, action.payload];
    },
    resetObstacles(state) {
      state.obstacles = [];
      state.obsCount = 0;
    },
    incrementObsCount(state) {
      state.obsCount = state.obsCount + 1;
    },
    setElementCounted(state, action: PayloadAction<number>) {
      state.obstacles[action.payload].counted = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBodyAndAddObstacle.fulfilled, (state, action) => {
      // обработка логики добавления препятствия в состояние
      state.obstacles = [...state.obstacles, action.payload];
    });
  },
});

export const { addObstacle, resetObstacles, incrementObsCount, setElementCounted } =
  obstacles.actions;

export default obstacles.reducer;
